





























































































































@import '~variables'

.login-form
  margin -150px auto 0 auto
  width 40%
  .login-form__title
    margin-bottom $space-3
  .login-form__row
    margin-bottom $space-1
  .login-form__button
    margin-top $space-3
    text-align center
    text-transform uppercase
    font-size $fs-h2
  .login-form__link
    display flex
    justify-content center
    margin-top 15px
    color $dark-pink
    text-decoration underline
    font-size 1.2rem
    cursor pointer
  .login-form__error
    color $negative
