@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.oasis-logo-big {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100%;
  text-decoration: none;
  user-select: none;
}
.oasis-logo-big .oasis-logo-big__svg {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  width: 200px;
  border: none;
  fill: none;
  stroke: #464749;
  stroke-miterlimit: 10;
  stroke-width: 30px;
}
.oasis-logo-big .oasis-logo-big__title {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  color: #464749;
  text-decoration: none;
  font-weight: 900;
  font-size: 2rem;
}
/*# sourceMappingURL=src/app/components/ui/logos/big-oasis-logo.css.map */