
























@import '~variables'

.oasis-logo-big
  display flex
  flex-direction column
  justify-content center
  margin 0
  padding 0
  height 100%
  text-decoration none
  user-select none
  .oasis-logo-big__svg
    display flex
    justify-content center
    margin-right 8px
    width 200px
    border none
    fill none
    stroke $dark
    stroke-miterlimit 10
    stroke-width 30px
  .oasis-logo-big__title
    display flex
    justify-content center
    margin-top 30px
    color $dark
    text-decoration none
    font-weight 900
    font-size 2rem
