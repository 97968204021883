@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.login-form {
  margin: -150px auto 0 auto;
  width: 40%;
}
.login-form .login-form__title {
  margin-bottom: 32px;
}
.login-form .login-form__row {
  margin-bottom: 8px;
}
.login-form .login-form__button {
  margin-top: 32px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.3rem;
}
.login-form .login-form__link {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  color: #f3afb3;
  text-decoration: underline;
  font-size: 1.2rem;
  cursor: pointer;
}
.login-form .login-form__error {
  color: #e91e63;
}
/*# sourceMappingURL=src/app/pages/login/components/login-form.css.map */