@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.base-container {
  display: flex;
  min-height: 100vh;
}
.base-container .logo-container,
.base-container .login-container {
  flex: 1;
}
.base-container .logo-container {
  position: relative;
  background-color: #ddd9d3;
}
.base-container .logo-container .oasis-logo-login {
  display: flex;
  justify-content: center;
  margin: 250px auto 0 auto;
  width: 300px;
}
.base-container .logo-container .oasis-logo-version {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: #464749;
  font-size: 1.4rem;
}
.base-container .logo-container .copyright {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  width: 100%;
}
.base-container .logo-container .copyright .copyright__message {
  margin-bottom: 16px;
  max-width: 450px;
  color: #8a8686;
  text-align: center;
  font-size: 1.3rem;
}
.base-container .logo-container .copyright .copyright__message:last-child {
  margin-bottom: 0;
}
.base-container .logo-container .copyright .europe-logo {
  width: 180px;
}
.base-container .login-container {
  display: flex;
  align-items: center;
  background-color: #fff;
}
@media screen and (max-width: 1000px) {
  .base-container {
    flex-direction: column;
  }
  .base-container .logo-container {
    order: 2;
  }
  .base-container .logo-container .oasis-logo-login {
    margin: 0 auto;
  }
  .base-container .logo-container .oasis-logo-login >>> .oasis-logo-big {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  .base-container .logo-container .oasis-logo-login >>> svg {
    width: 120px;
  }
  .base-container .logo-container .copyright {
    position: initial;
  }
  .base-container .login-container {
    order: 1;
    height: 80vh;
  }
  .base-container .login-container .login-form {
    margin: 0 auto;
    margin-bottom: 16px;
  }
  .base-container .login-container .login-form >>> h4 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1000px) and (max-width: 500px) {
  .login-form {
    width: 90%;
  }
}
/*# sourceMappingURL=src/app/pages/login/index.css.map */