









































@import '~variables'

.base-container
  display flex
  min-height 100vh
  .logo-container, .login-container
    flex 1
  .logo-container
    position relative
    background-color $silver
    .oasis-logo-login
      display flex
      justify-content center
      margin 250px auto 0 auto
      width 300px
    .oasis-logo-version
      display flex
      justify-content center
      margin-top 20px
      color $dark
      font-size 1.4rem
    .copyright
      position absolute
      bottom 0
      display flex
      flex-direction column
      align-items center
      padding 25px
      width 100%
      .copyright__message
        margin-bottom $space-2
        max-width 450px
        color $infos
        text-align center
        font-size $fs-h2
        &:last-child
          margin-bottom 0
      .europe-logo
        width 180px
  .login-container
    display flex
    align-items center
    background-color white

@media screen and (max-width: 1000px)
  .base-container
    flex-direction column
    .logo-container
      order 2
      .oasis-logo-login
        margin 0 auto
        & >>> .oasis-logo-big
          display flex
          justify-content center
          align-items center
          margin-top $space-2
        & >>> svg
          width 120px
      .copyright
        position initial
    .login-container
      order 1
      height 80vh
      .login-form
        margin 0 auto
        margin-bottom $space-2
        & >>> h4
          font-size $fs-big
  @media screen and (max-width: 500px)
    .login-form
      width 90%
